<template>
    <div class="management-rams">
        <admin-page :actions="actions" :fields="fields" :texts="texts" :noMainForm="true" sort-by="reference" :edit-in-form="true" :updateReturnItem="true"
                    :callbacks="{ beforeInsert: beforeInsertUpdate, beforeUpdate: beforeInsertUpdate, beforeShowEditAsync: beforeShowEdit }">
            <template v-slot:moreFormFields="{ item, index }">

                <div class="control-label">SOP</div>
                <div class="sop-control">
                    <control-checkbox v-model="item.isSop" :errors="fields[7].errors" class="">It is a SOP and require validation</control-checkbox>
                    <span class="questions" v-if="item && item.isSop">Questions: {{countSopQuestions}}</span>
                    <b-button size="sm" variant="secondary" :disabled="!item || !item.isSop" @click.stop="showSopQuestions({ item, index })" class="mr-1 edit-sop" right>Edit</b-button>
                </div>
                <control-tags v-if="item.isSop" :items="managers" v-model="item.sopManagers" label="Managers to validate SOP" :onlyAutocomplete="true" placeholder="click to select managers"></control-tags>

                <div class="control-label">Reinduction</div>
                <div class="sop-control">
                    <control-checkbox v-model="item.requiresReinduction" class="requires-reinduction">
                        Requires reinduction each
                        <control-text v-model="item.reinductionMonths" :read-only="!item.requiresReinduction" placeholder="x" type="number"
                                      :min="0" :max="99"></control-text>
                        months.
                    </control-checkbox>
                </div>

                <div class="control-label">RAMS file</div>
                <div v-if="item.hasVideo" class="has-video-file">
                    <icon name="film" class="film-icon"></icon>
                    <span>This RAM contains a video file</span>
                    <ram-viewer-button class="ml-auto" :ram-id="item.id" :has-video="true"></ram-viewer-button>
                    <!--<video-button class="ml-auto" :video-path="getVideoPath(item)"></video-button>-->
                </div>
                <control-file v-else v-model="item.ramFile" text="Drag your RAMS file here <br/> or click to browse"
                              :show-download="true" @download="downloadRamFile(item)" :show-open="true" @open="openRamFile(item)"></control-file>

            </template>
            <template v-slot:moreFormActions="{ item, index }">
                <b-button size="sm" variant="secondary" @click.stop="showHistory({ item, index })" class="mr-1" right>Show History</b-button>
            </template>
        </admin-page>

        <simple-modal ref="historyPopup" name="historyPopup" :key="'show-history-popup'" headerTitle="Version History" @cancel="clickOk" dialog-class="management-rams">
            <template slot="modal-content">
                <div id="history-popup" class="fixed-table-container">
                    <br />
                    <table class="table b-table table-striped table-sm table-with-border">
                        <thead>
                            <tr class="header even-row">
                                <th>Reference / RAM Code</th>
                                <th>Document Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="header even-row">
                                <td>{{historyMainItem.reference}}</td>
                                <td>{{historyMainItem.description}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <div id="history-table">
                        <table id="history-table" class="table b-table table-striped table-sm table-fixed table-with-border">
                            <thead>
                                <tr class="header even-row">
                                    <th class="col1"></th>
                                    <th class="col2">Version</th>
                                    <th class="col3">Issue date</th>
                                    <th class="col3">Valid issue date</th>
                                    <th class="col3">Review date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in historyItems" :key="'r_' + row.id">
                                    <td class="center col1">{{row.rowId}}</td>
                                    <td class="center col2">{{row.version}}</td>
                                    <td class="center col3">{{row.issueDateText}}</td>
                                    <td class="center col3">{{row.lastValidIssueDateText}}</td>
                                    <td class="center col3">{{row.reviewDateText}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <template slot="modal-buttons">
                <button type="button" class="btn btn-primary" @click="clickOk">OK</button>
            </template>
        </simple-modal>

        <simple-modal ref="sopQuestionsPopup" name="sopQuestionsPopup" :key="'sop-questions-popup'" headerTitle="SOP Management" @cancel="hideSopQuestionsPopup" @save="saveSopQuestionsPopup" dialog-class="management-rams">
            <template slot="modal-content">
                <br />
                <table class="table b-table table-striped table-sm table-with-border">
                    <thead>
                        <tr class="header even-row">
                            <th>Reference / RAM Code</th>
                            <th>Document Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="header even-row">
                            <td>{{sopItem ? sopItem.reference : ''}}</td>
                            <td>{{sopItem ? sopItem.description : ''}}</td>
                        </tr>
                    </tbody>
                </table>

                <!--<control-text label="SOP Title" size="sm" v-model="sopTitle" placeholder="enter SOP title"></control-text>
                <control-text label="SOP Description" size="sm" v-model="sopDescription" placeholder="enter SOP description" rows="3"></control-text>-->

                <div class="control-label">SOP Questions</div>
                <table class="table b-table table-striped table-sm" v-if="sopQuestions && sopQuestions.length > 0">
                    <thead>
                        <tr class="header even-row">
                            <th class="order">Order</th>
                            <th>Question</th>
                            <th class="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="header even-row" :key="index" v-for="(item, index) in sopQuestions">
                            <td class="order">{{item.order}}</td>
                            <td>
                                <div v-if="!item.editing" @click="startEditQuestion(item, index)">{{item.question}}</div>
                                <b-form-input :id="'edit-'+index" v-if="item.editing" size="sm" v-model="item.question" placeholder="enter question text" @blur="endEditQuestion(item)"
                                              class="question-edit"></b-form-input>
                            </td>
                            <td class="actions">
                                <icon name="arrow-up" :class="{ 'arrow-up-icon': true, 'disabled': index == 0 }" @click="questionUp(item)" :disabled="index == 0"></icon>
                                <icon name="arrow-down" :class="{ 'arrow-down-icon': true, 'disabled': isLastItem(index) }" @click="questionDown(item)" :disabled="isLastItem(index)"></icon>
                                <icon name="trash-alt" class="trash-icon" @click="deleteQuestion(item, index)"></icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="no-items">There are no SOP questions.</div>

                <div class="message">Please, enter new question and click add button.</div>
                <b-input-group size="sm" prepend="New question">
                    <b-form-input v-model="questionText" placeholder="enter new question" id="question-new" @keyup.enter="addQuestion"></b-form-input>
                    <b-input-group-append>
                        <b-btn @click.stop="addQuestion">Add</b-btn>
                    </b-input-group-append>
                </b-input-group>
            </template>
        </simple-modal>
    </div>
</template>
<script>
    import Vue from 'vue';

    export default {
        data() {
            return {
                fields: [
                    { key: 'reference', label: 'Reference / RAM code', sortable: true, validations: { required: true, maxLength: 50 }, placeholder: 'Enter reference or RAM code' },
                    { key: 'description', label: 'Document Title', sortable: true, validations: { required: true, maxLength: 500 }, placeholder: 'Enter document title', rows: 3 },
                    { key: 'version', label: 'Version', sortable: true, validations: { required: true, maxLength: 50 }, placeholder: 'Enter version', class: 'ram-version' },
                    { key: 'author', label: 'Author', sortable: true, dataType: 'select', value: 'authorId', options: [], placeholder: '-- Please, select an author --', class: 'ram-author' },
                    { key: 'issueDate', label: 'Issue Date', sortable: true, dataType: 'date', validations: { required: true }, placeholder: 'Pick a date', class: 'ram-date' },
                    { key: 'lastValidIssueDate', label: 'Last Valid Issue Date', sortable: true, dataType: 'date', validations: { required: true, lessOrEqualThan: 'issueDate' }, placeholder: 'Pick a date', class: 'ram-date' },
                    { key: 'reviewDate', label: 'Review Date', sortable: true, dataType: 'date', validations: { required: true, greaterOrEqualThan: 'issueDate' }, placeholder: 'Pick a date', class: 'ram-date2' },
                    {
                        key: 'isSop', label: 'SOP', isCustom: true, dataType: 'bool', htmlNo: '', controlClass: 'form-control',
                        htmlYes: '<svg aria-hidden="true" width="24" height="24" viewBox="0 0 512 512" focusable="false" class="fa-icon check-green-icon child-icon" x="0" y="0"><g><path d="M173.9 439.4l-166.4-166.4c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0l112.1 112.1 240.1-240.1c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2l-294.4 294.4c-10 10-26.2 10-36.2 0z"></path></g></svg>'
                    },
                    { key: 'ramFile', isCustom: true, dataType: 'file', download: this.downloadRamFile, open: this.openRamFile },
                ],
                actions: {
                    get: 'rams/getRams',
                    insert: 'rams/createUpdateRam',
                    update: 'rams/createUpdateRam',
                    delete: 'rams/removeRam',
                },
                texts: {
                    listTitle: 'RAMS',
                    newTitle: 'Add New RAM',
                    editTitle: 'Edit RAM',
                    addButton: 'Add RAM',
                    deleteButton: 'Remove',
                    itemName: 'ram',
                    askDelete: 'This action will delete the ram and related assignments and signatures. Are you sure?',
                },
                historyItems: [],
                historyMainItem: {},
                filePopupRam: null,
                filePopupFilename: null,
                ramFile: null,
                ramFileRemoved: false,
                sopItem: {},
                sopQuestions: [],
                questionText: null,
                managers: [],
            }
        },
        async created() {
            this.fields[3].options = await this.$store.dispatch('admin/getOptionsFromActiveEmployees');
            this.managers = await this.$store.dispatch('admin/getOptionsFromManagers');
        },
        mounted() {
        },
        computed: {
            countSopQuestions() {
                var item = this.sopItem;
                return (item && item.sopQuestions && item.sopQuestions.length ? item.sopQuestions.length : (item.sopQuestionsCount ? item.sopQuestionsCount : 0));
            },
        },
        methods: {
            async showHistory(row) {
                let result = await this.$store.dispatch('rams/getRamHistory', { ramId: row.item.id });
                if (result) {
                    this.historyMainItem = row.item;
                    this.loadHistoryItems(result);
                    this.$refs.historyPopup.show();
                }
            },
            loadHistoryItems(items) {
                this.historyItems = items;
                for (var i = 0; i < items.length; i++) {
                    items[i].rowId = i + 1;
                    items[i].issueDateText = $virtus.tools.dates.formatDate(items[i].issueDate);
                    items[i].reviewDateText = $virtus.tools.dates.formatDate(items[i].reviewDate);
                    items[i].lastValidIssueDateText = $virtus.tools.dates.formatDate(items[i].lastValidIssueDate);
                }
            },
            clickOk() {
                this.$refs.historyPopup.hide();
                this.historyMainItem = {};
                this.historyItems = [];
            },

            async downloadRamFile(item) {
                var result = await this.$store.dispatch('rams/getRamsFile', { ramId: item.id, download: true });
                if (result) {
                    $virtus.tools.messages.showSaveOk('rams file', 'downloaded');
                }
            },
            openRamFile(item) {
                //this.$store.dispatch('rams/getRamsFile', { ramId: item.id, download: false });
                this.$store.dispatch('rams/viewRam', { ramId: item.id, hasVideo: item.hasVideo, showTimer: false });
            },

            async showSopQuestions({ item, index }) {
                this.sopItem = item;
                this.sopTitle = item.sopTitle;
                this.sopDescription = item.sopDescription;
                this.sopQuestions = (item.sopQuestions ? this.sortQuestions(JSON.parse(JSON.stringify(item.sopQuestions))) : []);
                this.questionText = null;
                this.$refs.sopQuestionsPopup.show();
            },
            saveSopQuestionsPopup() {
                this.sopItem.sopTitle = this.sopTitle;
                this.sopItem.sopDescription = this.sopDescription;
                this.sopItem.sopQuestions = this.sopQuestions;
                this.$refs.sopQuestionsPopup.hide();
            },
            hideSopQuestionsPopup() {
                this.$refs.sopQuestionsPopup.hide();
            },
            addQuestion() {
                if (this.questionText) {
                    this.sopQuestions.push({
                        order: this.sopQuestions.length + 1,
                        question: this.questionText,
                        editing: false,
                    });
                    this.questionText = null;
                    document.getElementById('question-new').focus();
                }
            },
            questionUp(item) {
                if (item.order > 1) {
                    var previousQuestion = this.sopQuestions.find(q => q.order == item.order - 1);
                    previousQuestion.order++;
                    item.order--;
                    this.sortSopQuestions();
                }
            },
            questionDown(item) {
                if (item.order < this.sopQuestions.length) {
                    var nextQuestion = this.sopQuestions.find(q => q.order == item.order + 1);
                    nextQuestion.order--;
                    item.order++;
                    this.sortSopQuestions();
                }
            },
            deleteQuestion(item, index) {
                var deletedOrder = item.order;
                this.sopQuestions.splice(index, 1);
                for (var i in this.sopQuestions) {
                    if (this.sopQuestions[i].order > deletedOrder) this.sopQuestions[i].order--;
                }
                this.sortSopQuestions();
            },
            isLastItem(index) {
                return (this.sopQuestions && index >= this.sopQuestions.length - 1);
            },
            startEditQuestion(item, index) {
                item.editing = true;
                Vue.nextTick(function () {
                    document.getElementById('edit-' + index).focus();
                });
            },
            endEditQuestion(item) {
                item.editing = false;
            },
            sortSopQuestions() {
                this.sopQuestions = this.sortQuestions(this.sopQuestions);
            },
            sortQuestions(questions) {
                if (questions && questions.length) {
                    questions = questions.slice().sort((a, b) => {
                        return (a.order - b.order);
                    });
                    return questions;
                }
                return [];
            },
            beforeInsertUpdate(item) {
                var data = {                
                    id: item.id,
                    reference: item.reference,
                    description: item.description,
                    version: item.version,
                    issueDate: item.issueDate,
                    reviewDate: item.reviewDate,
                    lastValidIssueDate: item.lastValidIssueDate,
                    authorId: item.authorId,                    
                    ramFile: item.ramFile,
                    isSop: !!item.isSop,
                    requiresReinduction: (item.requiresReinduction && item.reinductionMonths > 0),
                    reinductionMonths: item.reinductionMonths,
                };
                if (item.isSop) {
                    data.sopQuestions = item.sopQuestions;
                    data.sopManagers = item.sopManagers || '';
                }
                return data;
            },
            async beforeShowEdit(item) {
                let result = await this.$store.dispatch('rams/getRamSopQuestions', { id: item.id });
                if (result) {
                    item.sopQuestions = this.sortQuestions(result.sopQuestions.map(q => { return { id: q.id, order: q.order, question: q.question, editing: false }; }));
                }
                if (!item.fileChecked) {
                    item.fileChecked = true;
                    let result = await this.$store.dispatch('rams/getRamsFilename', { ramId: item.id });
                    if (result) {
                        item.ramFile = { name: result };
                    }
                }
                this.sopItem = item;
                return item;
            },
            //getVideoPath(ram) {
            //    return (ram.hasVideo ? '/videos/rams/ram-' + ram.id + '.mp4' : '');
            //},
        }
    }
</script>
<style>
    .management-rams .table-with-border th,
    .management-rams .table-with-border td {
        border: 1px solid #dee2e6;
        text-align: center;
        margin: 0;
        vertical-align: middle;
        padding: 0.2rem 0.5rem;
    }
    .ram-file-download-title {
        font-size: large;
        font-weight: 600;
        margin-top: 0.5rem;
    }
    .management-rams .main-form {
        max-width: 700px;
    }
    .management-rams .main-form .ram-date {
        display: inline-block;
        margin-right: 2rem;
    }
    .management-rams .main-form .ram-date2 {
        display: inline-block;
        margin-right: 0;
    }
    .management-rams .main-form .ram-version {
        display: inline-block;
        padding-right: 2rem;
        width: 50%;
    }
    .management-rams .main-form .ram-author {
        display: inline-block;
        width: 50%;
    }

    .management-rams .modal-header {
        background-color: darkolivegreen;
        color: white;
    }

    .management-rams .arrow-up-icon,
    .management-rams .arrow-down-icon,
    .management-rams .trash-icon {
        margin: 0 0.2rem;
        cursor: pointer;
    }

    .management-rams .arrow-up-icon.disabled,
    .management-rams .arrow-down-icon.disabled,
    .management-rams .trash-icon.disabled {
        color: lightgray;
        cursor: default;
    }

    .management-rams .actions {
        text-align: center;
        width: 5rem;
    }

    .management-rams .order {
        text-align: center;
        width: 3rem;
    }

    .management-rams .no-items {
        margin-bottom: 1rem;
    }

    .management-rams .question-edit {
        
    }

    .management-rams .message {
        margin-left: 0;
    }

    .management-rams .actions-column {
        width: auto;
    }

    .management-rams .sop-control {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        padding: .375rem .5rem;
    }
    .management-rams .sop-control .questions {
        margin-left: auto;
        padding: 0 0.5rem;
    }

    .management-rams .edit-sop {
        border-radius: 10px;
        padding: 0 10px;
    }

    .management-rams .has-video-file {
        margin-top: 0.5rem;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        padding: .375rem .5rem;
        display: flex;
        align-items: center;
    }

    .management-rams .has-video-file .film-icon {
        margin-right: 0.5rem;
    }

    .management-rams .requires-reinduction div {
        display: flex;
        align-items: center;
    }

    .management-rams .requires-reinduction .control-text {
        display: inline-block;
    }

    .management-rams .requires-reinduction .control-text .read-only,
    .management-rams .requires-reinduction .control-text input {
        width: 3rem;
        font-size: small;
        padding: 0 0.2rem 0 0.4rem;
        height: 1.5rem;
        min-height: 1.5rem;
        margin: 0.2rem;
    }

    
</style>
