import axios from 'axios';
import dates from './dates';
import loader from './loader';
import messages from './messages';

//Get the API host
var API_HOST = $virtus.config.environment.api;

function createFormData(data) {
    var formData = new FormData();
    for (var i in data) {
        formData.append(i, data[i]);
    }
    return formData;
}

function processHeaders(options) {
    if (options && options.isFormData) {
        if (!options.headers) options.headers = {};
        options.headers['Content-Type'] = 'multipart/form-data';
    }
    if (options && options.isResponseArrayBuffer) {
        options.responseType = 'arraybuffer';
    }
}

function convertArrayFieldsToJson(item, fields) {
    for (var i in fields) {
        var field = fields[i];
        if (item[field]) {
            item[field + 'Json'] = JSON.stringify(item[field]);
            item[field] = null;
        }
    }
}

function processDataForServer(data, options) {
    if (options) {
        if (Array.isArray(data)) {
            if (options.dateFields) {
                data = dates.convertArrayDatesToServer(data, options.dateFields);
            }
            if (options.isFormData && options.arrayFields) {
                for (var i in data) {
                    convertArrayFieldsToJson(data[i], options.arrayFields);
                }
            }
        } else {
            if (options.dateFields) {
                data = dates.convertObjectDatesToServer(data, options.dateFields);
            }
            if (options.dateTimeFields) {
                data = dates.convertObjectDateTimesToServer(data, options.dateTimeFields);
            }
            if (options.isFormData && options.arrayFields) {
                convertArrayFieldsToJson(data, options.arrayFields);
            }
        }
        if (options.isFormData) {
            data = createFormData(data);
        }
    }
    return data;
}

function processDataFromServer(data, options) {
    if (Array.isArray(data)) {
        if (options && options.dateFields) {
            data = dates.convertArrayDatesFromServer(data, options.dateFields);
        }
    } else {
        if (options && options.dateFields) {
            data = dates.convertObjectDatesFromServer(data, options.dateFields);
        }
        if (options && options.dateTimeFields) {
            data = dates.convertObjectDateTimesFromServer(data, options.dateTimeFields);
        }
    }
    return data;
}


function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}

function saveByteArray(fileName, byte, mimeType) {
    window.URL = window.URL || window.webkitURL;
    var blob = new Blob([byte], { type: mimeType });
    var os = getMobileOperatingSystem();
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
    } else if (os == "iOS") {
        var reader = new FileReader();
        reader.onload = function (/*e*/) {
            window.location.href = reader.result;
            //window.open(reader.result);
        }
        reader.readAsDataURL(blob);
    } else {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        //link.href=window.webkitURL.createObjectURL(blob);
        //a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
        link.download = fileName.substr(fileName.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

function openByteArray(fileName, byte, mimeType, iframe) {
    window.URL = window.URL || window.webkitURL;
    var blob = new Blob([byte], { type: mimeType });
    var source = URL.createObjectURL(blob);
    if (iframe) {
        iframe.src = source;
    } else {
        var newWindow = window.open();
        newWindow.document.title = fileName;
        newWindow.document.write(`<iframe src="${source}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
    }
}

function saveResponse(response) {
    if (response && response.data != null && response.status == 200 && response.headers) {
        var disposition = response.headers['content-disposition'];
        var contentType = response.headers['content-type'];
        if (disposition && contentType) {
            var filename = "";
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
            if (filename) {
                saveByteArray(filename, response.data, contentType);
                return true;
            }
        }
    }
    return false;
}

function openResponse(response, iframe) {
    if (response && response.data != null && response.status == 200 && response.headers) {
        var disposition = response.headers['content-disposition'];
        var contentType = response.headers['content-type'];
        if (disposition && contentType) {
            var filename = "";
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
            if (filename) {
                openByteArray(filename, response.data, contentType, iframe);
                return true;
            }
        }
    }
    return false;
}


let api = {

    async getFile(url, options) {
        var result = null;
        options = options || {};
        if (!options.noLoader) loader.show();
        try {
            result = await axios.get(API_HOST + url, {
                responseType: 'arraybuffer',
                customErrorHandler: (error) => {
                    if (error && error.response && error.response.status === 404) {
                        messages.error('File not found', 'Sorry, the file was not found.');
                        return true;
                    }
                    return false;
                }
            });
        } catch (e) {
            result = null;
            if (e.response && e.response.status != 409) {
                throw e;
            }
        } finally {
            if (!options.noLoader) loader.close();
        }
        return result;
    },

    async download(url, options) {
        var result = false;
        options = options || {};
        if (!options.noLoader) loader.show();
        try {
            let response = await this.getFile(url, options);
            if (response) {
                result = saveResponse(response);
            }
        } catch (e) {
            result = false;
        } finally {
            if (!options.noLoader) loader.close();
        }
        return result;
    },

    async open(url, options) {
        var result = null;
        options = options || {};
        if (!options.noLoader) loader.show();
        try {
            let response = await this.getFile(url, options);
            if (response) {
                result = openResponse(response, options.iframe);
            }
        } catch (e) {
            result = false;
        } finally {
            if (!options.noLoader) loader.close();
        }
        return result;
    },

    async openExcel(url) {
        var key = encodeURIComponent(btoa(API_HOST + url));
        var result = await this.open('/viewer/' + key);
        return result;
    },

    async get(url, options) {
        var result = null;
        options = options || {};
        if (!options.noLoader) loader.show();
        try {
            processHeaders(options);
            let response = await axios.get(API_HOST + url, options);
            if (response && response.data != null && response.status == 200) {
                result = processDataFromServer(response.data, options);
            }
        } catch (e) {
            result = null;
            if (e.response && e.response.status != 409) {
                throw e;
            }
        } finally {
            if (!options.noLoader) loader.close();
        }
        return result;
    },

    async post(url, data, options) {
        var result = null;
        options = options || {};
        if (!options.noLoader) loader.show();
        try {
            processHeaders(options);
            let dataForServer = processDataForServer(data, options);
            let response = await axios.post(API_HOST + url, dataForServer, options);
            if (response && response.status == 204) {
                result = true;
            } else if (response && response.data != null && response.status == 200) {
                result = processDataFromServer(response.data, options);
            }
        } catch (e) {
            result = null;
            if (e.response && e.response.status != 409) {
                throw e;
            }
        } finally {
            if (!options.noLoader) loader.close();
        }
        return result;
    },

    async put(url, data, options) {
        var result = false;
        options = options || {};
        if (!options.noLoader) loader.show();
        try {
            processHeaders(options);
            let dataForServer = processDataForServer(data, options);
            let response = await axios.put(API_HOST + url, dataForServer, options);
            if (response && (response.status == 204 || response.status == 200)) {
                result = (options && options.returnResult && response.data != null ? response.data : true);
            }
        } catch (e) {
            result = false;
            if (e.response && e.response.status != 409) {
                throw e;
            }
        } finally {
            if (!options.noLoader) loader.close();
        }
        return result;
    },

    async delete(url, options) {
        var result = false;
        options = options || {};
        if (!options.noLoader) loader.show();
        try {
            processHeaders(options);
            let response = await axios.delete(API_HOST + url, options);
            if (response && response.status == 204) {
                result = true;
            }
        } catch (e) {
            result = false;
            if (e.response && e.response.status != 409) {
                throw e;
            }
        } finally {
            if (!options.noLoader) loader.close();
        }
        return result;
    },

};

export default api;
