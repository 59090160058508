<template>
    <div class="ram-viewer">
        <ram-viewer-header ref="ramViewerHeader" :ramId="ramId" :returnPath="returnPath" :showTimer="showTimer" v-if="ramId"></ram-viewer-header>
        <video v-if="videoPath" width="1280" height="720" id="video" controls autoplay>
            <source :src="videoPath" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <!--<iframe v-show="hasVideo===false" ref="pdfViewer" src="about:blank" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>-->
        <pdf v-show="hasVideo === false" :pdf="pdfData"></pdf>
    </div>
</template>
<script>
    import pdf from 'vue-pdf-app';
    import "vue-pdf-app/dist/icons/main.css";

    export default {
        name: 'ram-viewer',
        components: { pdf },
        data() {
            return {
                ramId: null,
                hasVideo: null,
                showTimer: null,
                returnPath: null,
                pdfData: null
            }
        },
        async created() {
        },
        async mounted() {
            await this.loadConfig();
        },
        async beforeRouteLeave(to, from, next) {
            await this.$refs.ramViewerHeader.routeLeave();
            next();
        },
        computed: {
            videoPath() {
                return (this.hasVideo ? '/videos/rams/ram-' + this.ramId + '.mp4' : '');
            },
        },
        methods: {
            async loadConfig() {
                var key = this.$route.params.key;
                if (key) {
                    var config = JSON.parse(atob(decodeURIComponent(key)));
                    if (config) {
                        this.ramId = config.ramId;
                        this.hasVideo = config.hasVideo;
                        this.showTimer = config.showTimer;
                        this.returnPath = config.returnPath;
                    }
                }
                debugger;
                if (this.ramId && this.hasVideo === false) {

                    var response = await this.$store.dispatch('rams/getRamsFile', { ramId: this.ramId, onlyBytes: true });
                    if (response !== null && response !== true && response !== false) {
                        this.pdfData = response.data;
                    }
                    //Show pdf inside the iframe
                    //await this.$store.dispatch('rams/getRamsFile', { ramId: this.ramId, download: false, iframe: this.$refs.pdfViewer });
                }
            },
        }
    }

</script>
<style>

    .ram-viewer {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        overflow: hidden;
    }

        .ram-viewer iframe,
        .ram-viewer #video {
            /*            z-index: 100;
            width: 100%;
            height: 100%;*/
            flex-grow: 1;
            border: none;
            margin: 0;
            padding: 0;
            background-color: black;
        }

    .page-container.show-viewer,
    .page-container.show-viewer .app-view {
        background-color: black;
        padding: 0;
    }

        .page-container.show-viewer .content {
            padding: 0;
            top: 0;
        }

        .page-container.show-viewer .navbar-container {
            display: none;
        }

        .page-container.show-viewer .page-footer {
            font-color: white;
        }

        .page-container.show-viewer .app-view .ram-viewer-header table {
            width: 100%;
            background-color: black;
            margin: 0.5rem;
        }
            .page-container.show-viewer .app-view .ram-viewer-header table td {
                color: white;
            }

</style>
