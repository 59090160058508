module.exports = {
    LEAVER_SHIFT_ID: 1,
    apps: [
        { id: 1, name: 'Administration', home: '/admin' },
        { id: 2, name: 'Record', home: '/record' },
        { id: 3, name: 'My Work', home: '/mywork' },
    ],
    sites: [
        { id: 1, name: 'Tovi Eco Park', logos: ['/images/logo-tovi.jpg'] },
        { id: 2, name: 'Waltham Forest', logos: ['/images/logo-urbaser.jpg', '/images/logo-waltham-forest.png'] },
        { id: 3, name: 'Windsor & Wokingham', logos: ['/images/logo-urbaser.jpg', '/images/logo-windsor.png', '/images/logo-wokingham.png'] },
        { id: 4, name: 'Gosport Borough Council', logos: ['/images/logo-urbaser.jpg', '/images/logo-gosport.png'] },
        { id: 6, name: 'Dartford', logos: ['/images/logo-urbaser.jpg'] },
        { id: 7, name: 'North Herts Letchworth / East Herts Buntingford', logos: ['/images/logo-urbaser.jpg', '/images/logo-herts-1.png', '/images/logo-herts-2.png'] },
        { id: 8, name: 'Welwyn Hatfield', logos: ['/images/logo-urbaser.jpg'] },
        { id: 9, name: 'Selby / Eden', logos: ['/images/logo-urbaser.jpg'] },
        { id: 10, name: 'Burnley', logos: ['/images/logo-urbaser.jpg'] },
        { id: 11, name: 'Tunbridge Wells / Tonbridge & Malling', logos: ['/images/logo-urbaser.jpg'] },
        { id: 12, name: 'HWRC Central Beds & Nhants', logos: ['/images/logo-urbaser.jpg'] },
        { id: 13, name: 'Havering', logos: ['/images/logo-urbaser.jpg'] },
    ],
    environments: [
        { id: 'localhost', name: 'LOCALHOST', hostname: 'localhost', api: 'https://localhost:7269', videos: '' },
        { id: 'dev', name: 'DEVELOPMENT', hostname: 'dev.virtus-appweb.com', api: '', videos: '' },
        { id: 'test', name: 'TEST', hostname: 'test.virtus-appweb.com', api: '', videos: '' },
        { id: 'demo', name: 'DEMO', hostname: 'demo.virtus-appweb.com', api: '', videos: '' },
        { id: 'pro', name: 'PRODUCTION', hostname: 'app.virtus-appweb.com', api: '', videos: '' },
        { id: 'azure', name: 'AZURE', hostname: 'polite-water-0f37aba03.5.azurestaticapps.net', api: 'https://api-virtusapp-pro.azurewebsites.net', videos: 'https://st1virtusapp1pro.z33.web.core.windows.net' },
    ]
}
