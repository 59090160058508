import api from '../tools/api';
import stores from '../tools/stores';
import cookies from '../tools/cookies';
import { profiles } from './profiles';

const AUTHENTICATION_COOKIE_NAME = 'auth_cookie_virtus_app',
    SITE_ID_COOKIE_NAME = 'site_id_virtus_app',
    APP_ID_COOKIE_NAME = 'app_id_virtus_app';

var environmentName = 'unknown';
api.get('/api/security/environment-name').then((result) => {
    if (result) {
        environmentName = result;
    }
    console.log('%c' + environmentName + ' environment', 'background-color: black; color: white; display: block;padding: 2px 100px; border-radius:5px;text-align: center;font-weight: bold;');
});

const getDefaultState = () => {
    return {
        appId: null,
        siteId: null,
        //siteName: null,
        userId: null,
        userName: null,
        userProfiles: [],
        viewVersion: 0,
        //showViewer: false,
        token: null,
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getAppId(state) {
            if (!state.appId) {
                console.log('security: appId is not configured.')
            }
            return state.appId;
        },
        getSiteId(state) {
            return state.siteId;
        },
        getSiteName(state, getters) {
            return (state.siteId ? getters.getUserSites[state.siteId].siteName : '');
        },
        isLoggedIn(state) {
            return (!!state.userId);
        },
        //getUserId(state) {
        //    return state.userId;
        //},
        //getUserName(state) {
        //    return state.userName;
        //},
        //getUserProfiles(state) {
        //    return state.userProfiles;
        //},
        getCurrentProfileId(state) {
            var profile = state.userProfiles.find(up => up.siteId == state.siteId);
            if (profile && profile.profileId) {
                return profile.profileId;
            }
            return 0;
        },
        //appNames(state) {
        //    return stores.convertArrayToDictionary($virtus.config.apps);
        //},
        //siteNames(state) {
        //    return stores.convertArrayToDictionary($virtus.config.sites);
        //},
        getUserAppIds(state, getters) {
            var result = [];
            if (state.userProfiles) {
                var profileId = getters.getCurrentProfileId;
                if (!profileId) profileId = state.userProfiles[0].profileId;
                switch (profileId) {
                    case profiles.PlatformAdmin:
                    case profiles.Administrator:
                        result = [1, 2, 3];
                        break;
                    case profiles.Manager:
                        result = [2, 3];
                        break;
                    case profiles.Worker:
                        result = [3];
                        break;
                    case profiles.SkillManager:
                        result = [2];
                        break;
                }
            }
            return result;
        },
        getUserApps(state, getters) {
            var appIds = getters.getUserAppIds;
            var apps = $virtus.config.apps.filter(app => appIds.includes(app.id))
                .map(app => ({ appId: app.id, appName: app.name, selected: (app.id === state.appId) }));
            var result = Object.assign({}, ...apps.map(x => ({ [x.appId]: x })));
            return result;
            //var apps = {};
            //var appNames = getters.appNames;
            //var currentAppId = state.appId;
            //for (var i in state.userProfiles) {
            //    var profile = state.userProfiles[i];
            //    if (!apps[profile.appId]) {
            //        apps[profile.appId] = { appId: profile.appId, appName: appNames[profile.appId].name, selected: false };
            //    }
            //    if (profile.appId == currentAppId) {
            //        apps[profile.appId].selected = true;
            //    }
            //}
            //return apps;
        },
        getUserSiteIds(state) {
            var userSiteIds = state.userProfiles.map(x => x.siteId);
            return userSiteIds;
        },
        getUserSites(state, getters) {
            var userSiteIds = getters.getUserSiteIds;
            var userSites = $virtus.config.sites.filter(x => userSiteIds.includes(x.id))
                .map(site => ({ siteId: site.id, siteName: site.name, selected: (site.id === state.siteId) }));
            var result = Object.assign({}, ...userSites.map(x => ({ [x.siteId]: x })));
            return result;
        //    var sites = {};
        //    var siteNames = getters.siteNames;
        //    //var currentAppId = state.appId;
        //    var currentSiteId = state.siteId;
        //    for (var i in state.userProfiles) {
        //        var profile = state.userProfiles[i];
        //        //if (profile.appId == currentAppId) {
        //        if (!sites[profile.siteId]) {
        //                sites[profile.siteId] = { siteId: profile.siteId, siteName: siteNames[profile.siteId].name, selected: false };
        //            }
        //            if (profile.siteId == currentSiteId) {
        //                sites[profile.siteId].selected = true;
        //            }
        //        //}
        //    }
        //    return sites;
        },
        //getHomeRoute(state, getters) {
        //    var route = null;
        //    if (state.homeRoutes && state.homeRoutes.length > 0) {
        //        var profileId = getters['getCurrentProfileId'];
        //        route = state.homeRoutes.find(r => r.profile == profileId);
        //    }
        //    if (!route) {
        //        route = { name: 'default', path: '/record' };
        //    }
        //    return route;
        //},
        //getProfiles() {
        //    return profiles;
        //},
        getEnvironmentName() {
            return environmentName;
        },
        //isAnonymousRoute(state, getters, rootState, rootGetters) {
        //    var path = $virtus.router.history.current.path;
        //    var anonymousPaths = $virtus.config.anonymousPaths;
        //    for (var i in anonymousPaths) {
        //        if (path.startsWith(anonymousPaths[i])) {
        //            return true;
        //        }
        //    }
        //    return false;
        //}
        getToken(state) {
            if (state.token) {
                return state.token;
            } else {
                //return localStorage.getItem('accessToken');
                return cookies.readCookie(AUTHENTICATION_COOKIE_NAME);
            }
        },
        isPlatformAdmin(state) {
            return (state.userId == 1 || state.userId == 23);
        },
    },
    mutations: {

        clearState(state) {
            var appId = state.appId;
            var siteId = state.siteId;
            Object.assign(state, getDefaultState());
            state.appId = appId;
            state.siteId = siteId;
        //    var appId = state.appId;
        //    var homeRoutes = state.homeRoutes;
        //    Object.assign(state, getDefaultState());
        //    state.appId = appId;
        //    state.homeRoutes = homeRoutes;
        },
        //clearCookies(state) {
        //    cookies.deleteCookie(AUTHENTICATION_COOKIE_NAME);
        //    cookies.deleteCookie(SITE_ID_COOKIE_NAME);
        //    cookies.deleteCookie(APP_ID_COOKIE_NAME);
        //},
        //setHomeRoutes(state, homeRoutes) {
        //    state.homeRoutes = homeRoutes;
        //},
        //setLoginTitle(state, title) {
        //    state.loginTitle = title;
        //},
        //setShowViewer(state, show) {
        //    state.showViewer = show;
        //    console.log('showViewer = ' + show);
        //},
        setToken(state, token) {
            state.token = token;
            //localStorage.setItem('accessToken', token);
            cookies.setCookie(AUTHENTICATION_COOKIE_NAME, token);
        },
    },
    actions: {

        async setUserInfo({ state, getters, dispatch }, data) {
            state.userId = data.userId;
            state.userName = data.userName;
            state.userProfiles = data.userProfiles;

            //Check that the user has any app and site
            var siteIds = getters.getUserSiteIds;
            var appIds = getters.getUserAppIds;
            if (!siteIds || !siteIds.length || !appIds || !appIds.length) {
                dispatch('goToLogin');
                return;
            }

            //Check user has rights on current app, if not change app to first
            if (!state.appId || !appIds.includes(state.appId)) {
                //state.appId = appIds[0];
                await dispatch('setAppId', appIds[0]);
            }

            //Check user has rights on current site, if not change site to first
            if (!state.siteId || !siteIds.includes(state.siteId)) {
                //state.siteId = siteIds[0];
                await dispatch('setSiteId', siteIds[0]);
            }
        },

        async setAppId({ state, getters }, appId) {
            if (state.appId != appId) {
                var appIds = getters.getUserAppIds;
                if (appIds) {
                    if (appIds.includes(appId)) {
                        state.appId = appId;
                        cookies.setCookie(APP_ID_COOKIE_NAME, appId);
                        state.viewVersion++;
                        return true;
                    }
                    console.log('The user has no rights on the app: ' + appId);
                    return false;
                }
                console.log('The user profiles are not loaded.');
                return false;
            }
            return true;
        },

        async setSiteId({ state, getters }, siteId) {
            if (state.siteId != siteId) {
                var siteIds = getters.getUserSiteIds;
                if (siteIds) {
                    if (siteIds.includes(siteId)) {
                        state.siteId = siteId;
                        cookies.setCookie(SITE_ID_COOKIE_NAME, siteId);
                        state.viewVersion++;
                        return true;
                    }
                    console.log('The user has no rights on the site: ' + siteId);
                    return false;
                }
                console.log('The user profiles are not loaded.');
                return false;
            }
            return true;
        },

        //async login({ dispatch }, data) {
        //    try {
        //        let responseData = await api.post('/api/security/login', data);
        //        if (responseData && responseData.userId) {
        //            await dispatch('setUserInfo', responseData);
        //            dispatch('goToRedirectPage');
        //            return true;
        //        }
        //    } catch { return false; }
        //    return false;
        //},

        async loginToken({ commit, dispatch }, data) {
            try {
                let responseData = await api.post('/api/security/token', data);
                if (responseData && responseData.userId && responseData.token) {
                    commit('setToken', responseData.token);
                    await dispatch('setUserInfo', responseData);
                    dispatch('goToRedirectPage');
                    return true;
                }
            } catch { }
            return false;
        },

        //async isAnonymousPath({ state }, { path }) {
        //    var anonymousPaths = state.anonymousPaths;
        //    for (var i in anonymousPaths) {
        //        if (path.startsWith(anonymousPaths[i])) {
        //            return true;
        //        }
        //    }
        //    return false;
        //},

        //async checkLogin({ getters, dispatch }) {
        //    var isAnonymous = await dispatch('isAnonymousPath');
        //    if (!isAnonymous && !getters.isLoggedIn) {
        //        dispatch('goToLogin');
        //    }
        //},

        async tryToLogin({ dispatch }) {
            var loggedIn = await dispatch('loadSecurityContextToken');
            if (loggedIn) {
                await dispatch('goToRedirectPage');
            } else {
                await dispatch('goToLoginWithRedirect');
            }
        },

        async loadSecurityContextToken({ state, getters, commit, dispatch }) {
            if (state.userId && state.appId && state.siteId && getters.getToken)
                return true;

            //Set appId from cookie
            var appCookie = cookies.readCookie(APP_ID_COOKIE_NAME);
            var appId = parseInt(appCookie);
            if (appId) {
                state.appId = appId;
            }
            //Set siteId from cookie
            var siteCookie = cookies.readCookie(SITE_ID_COOKIE_NAME);
            var siteId = parseInt(siteCookie);
            if (siteId) {
                state.siteId = siteId;
            }
            //Try to login from token in local storage
            if (getters.getToken) {
                //Try to login
                try {
                    let responseData = await api.get('/api/security/token');
                    if (responseData && responseData.userId && responseData.token) {
                        commit('setToken', responseData.token);
                        await dispatch('setUserInfo', responseData);
                        return true;
                    } 
                } catch (ex) {
                    //console.log(ex);
                }
            }
            return false;
            //If not anonymous page, go to login
            ///OJO: corregir anonimo, probar si try to login funciona, agregar param appId y establecer app y cookie
            //if (!getters.isAnonymousRoute) {
            //dispatch('goToLogin', $virtus.router.currentRoute.query.redirect);
            //    }
        },

        //async loadSecurityContext({ state, getters, dispatch }) {
        //    //Set appId from cookie
        //    var appCookie = cookies.readCookie(APP_ID_COOKIE_NAME);
        //    var appId = parseInt(appCookie);
        //    if (appId) {
        //        state.appId = appId;
        //    }
        //    //Set siteId from cookie
        //    var siteCookie = cookies.readCookie(SITE_ID_COOKIE_NAME);
        //    var siteId = parseInt(siteCookie);
        //    if (siteId) {
        //        state.siteId = siteId;
        //    }
        //    //Try to login from cookie
        //    if (cookies.readCookie(state.AUTHENTICATION_COOKIE_NAME)) {
        //        //Try to login
        //        try {
        //            let responseData = await api.get('/api/security/user-info');
        //            if (responseData && responseData.userId) {
        //                await dispatch('setUserInfo', responseData);
        //                dispatch('goToRedirectPage');
        //                return true;
        //            }
        //        } catch (ex) {
        //            //The user is not logged in, do logout
        //          }
        //    }
        //    //If not anonymous page, go to login
        //    ///OJO: corregir anonimo, probar si try to login funciona, agregar param appId y establecer app y cookie
        //    //if (!getters.isAnonymousRoute) {
        //        dispatch('goToLogin', $virtus.router.currentRoute.query.redirect);
        ////    }
        //},

        //async tryToLogin({ state, getters, dispatch }) {
        //    if (getters.isLoggedIn) {
        //        return true;
        //    } else if (cookies.readCookie(state.AUTHENTICATION_COOKIE_NAME)) {
        //        //Try to read siteId from query string
        //        var urlParams = new URLSearchParams(window.location.search);
        //        var siteId = urlParams.get('siteId');
        //        if (siteId) {
        //            state.siteId = siteId;
        //        }
        //        //Try to login
        //        try {
        //            let responseData = await api.get('/api/security/user-info');
        //            if (responseData && responseData.userId) {
        //                await dispatch('setUserInfo', responseData);
        //                dispatch('goToRedirectPage');
        //                return true;
        //            }
        //        } catch (ex) {
        //            //The user is not logged in, do logout
        //        }
        //        dispatch('logout');
        //    }
        //    return false;
        //},

        async logout({ commit, dispatch }) {
            //try {
            //    await api.post('/api/security/logout');
            //} catch (ex) {
            //    //The user is not logged in
            //}
            //commit('clearState');
            //commit('setToken', null);
            //commit('clearCookies');
            commit('clearState');
            commit('setToken', null);
            dispatch('goToLogin');
        },


        async goToLoginWithRedirect({ dispatch }) {
            await dispatch('goToLogin', $virtus.router.currentRoute.query.redirect);
        },

        async goToLogin({ dispatch }, redirectUrl) {
            ////Do logout
            //try {
            //    await api.post('/api/security/logout');
            //} catch (ex) {
            //    //The user is not logged in
            //}

            //Clear authentication cookie
            //cookies.deleteCookie(AUTHENTICATION_COOKIE_NAME);
            //Prepare redirect param
            var url = '/login';
            if (redirectUrl) {
                url += '?redirect=' + encodeURIComponent(redirectUrl);
            }
            //Go to login page
            await dispatch('routeTo', url);
        },

        async goToRedirectPage({ state, dispatch }) {
            var router = $virtus.router;
            var redirectTo = router.currentRoute.query.redirect;
            if (!redirectTo) {
                await dispatch('goToApp', state.appId);
                return;
            }
            await dispatch('routeTo', redirectTo);
        },

        async routeTo({ state, dispatch } , url) {
            var router = $virtus.router;
            //if (router.history.current.path != url) {
            if (router.currentRoute.fullPath != url) {
                //If the app of the url is different, change the app
                var appIdUrl = (url.startsWith('/admin') ? 1 : (url.startsWith('/record') ? 2 : (url.startsWith('/mywork') ? 3 : null)));
                if (appIdUrl && state.appId != appIdUrl) {
                    if (!await dispatch('setAppId', appIdUrl)) {
                        await dispatch('goToLogin');
                        return;
                    }
                }
                //Only navigate if the user has rights on that app, otherwise navigate to root of app or login
                if (!appIdUrl || state.appId == appIdUrl) {
                    router.push(url);
                } else {
                    await dispatch('goToApp', state.appId);
                }
            }
        },

        //async getProfiles({ state } , data) {
        //    var result = state.profiles;
        //    if (!result) {
        //        result = await api.get('/api/security/profiles?appId=' + (data.appId ? data.appId : 0) + '&siteId=' + (data.siteId ? data.siteId : 0));
        //        state.profiles = result;
        //    }
        //    return result;
        //},

        //async getOptionsFromProfiles({ dispatch }, data) {
        //    var items = await dispatch('getProfiles', data);
        //    var options = stores.getOptionsFromArray(items, 'id', 'name');
        //    return options;
        //},

        async changePassword(store, data) {
            var result = await api.post('/api/security/change-password', { token: data.token, password: data.password });
            return result;
        },

        async forgottenPassword(store, data) {
            var result = await api.post('/api/security/forgotten-password', { email: data.email });
            return result;
        },

        async getUserRightsInfo(store, data) {
            var result = await api.get('/api/security/users/' + data.userId + '/rights-info');
            return result;
        },

        async updateUserRightsInfo(store, data) {
            var result = await api.post('/api/security/users/' + data.userId + '/rights-info', data);
            return result;
        },

        //async goBack({ state }) {
        //    var router = $virtus.router;
        //    state.showViewer = false;
        //    router.back();
        //},

        async goToApp({ state, getters, dispatch }, appId) {
            if (!getters.getUserAppIds.includes(appId)) {
                await dispatch('goToLogin');
                return;
            }
            var app = $virtus.config.apps.find(app => app.id === appId);
            if (app) {
                await dispatch('routeTo', app.home);
            } else {
                console.log('The app with id ' + appId + ' does not exist.');
                await dispatch('goToLogin');
            }
            //    var hostname = window.document.location.hostname;
            //    var isLocal = (hostname.indexOf('localhost') >= 0);
            //    var isDev = (hostname.indexOf('dev') >= 0);
            //    var isTest = (hostname.indexOf('test') >= 0);
            //    var url = null;
            //    switch (appId) {
            //        case 1:
            //            url = (isLocal ? 'https://localhost:44400' : (isDev ? 'https://dev-admin.virtus-appweb.com' : (isTest ? 'https://test-admin.virtus-appweb.com' : 'https://admin.virtus-appweb.com')));
            //            break;
            //        case 2:
            //            url = (isLocal ? 'https://localhost:44401' : (isDev ? 'https://dev-record.virtus-appweb.com' : (isTest ? 'https://test-record.virtus-appweb.com' : 'https://record.virtus-appweb.com')));
            //            break;
            //    }
            //    if (url) {
            //        window.document.location.href = url + '?siteId=' + state.siteId;
            //    }
        },

    }
};
