import Vue from 'vue';
import controls from './controls'
import security from "./security";
import tools from "./tools";
import config from '../app.config';

let virtus = {
    install,
    configure,
    controls,
    security,
    tools,
    config
};

function install(/*Vue*/) {
    if (install.installed) return;
    install.installed = true;

    //Set environment
    var hostname = window.document.location.hostname;
    for (var i in virtus.config.environments) {
        var environment = virtus.config.environments[i];
        if (hostname.indexOf(environment.hostname) >= 0) {
            virtus.config.environment = environment;
            console.log('client environment: ' + environment.name);
            break;
        }
    }
    if (!virtus.config.environment) {
        console.log('client environment: unknown');
    }

    window.$virtus = virtus;
    //Vue.prototype.$virtus = virtus;
    //Vue.prototype.tools = virtus.tools;
    //window.virtus = {
    //    controls,
    //    security,
    //    tools,
    //    router: null,
    //    store: null,
    //};
    //Vue.prototype.tools = tools;
}

function configure(items) {

    if (items) {
        virtus.router = items.router;
        virtus.store = items.store;
        controls.configure(items);
        security.configure(items);
    }
}

Vue.use(virtus);

export default virtus;
